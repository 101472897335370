import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";

import "../index.css";

const CopilotSuggestionCard = ({
  data,
  onActionClick,
  addTransition,
  // newChat,
  getListIndex,
  currentIndex,
  selected,
  selectedIndex,
  onClick,
  gradientFirstColor,
  gradientSecondColor,
}) => {
  const [newIndex, setNewIndex] = useState(-1);
  useEffect(() => {
    getListIndex(data.items.length);
  }, []);

  useEffect(() => {
    if (newIndex > data.items.length) {
      setNewIndex(0);
    } else {
      setNewIndex((prev) => (prev + 1) % data.items.length);
    }
  }, [currentIndex]);

  return (
    <Box
      sx={{
        height: "270px",
        width: "33%",
        background: "#fff",
        border: "1px solid #d0d5dd6e",
        borderRadius: "30px",
        display: "flex",
        flexDirection: "column",
        px: 3.5,
        py: 4,
        gap: 3,
        overflow: "hidden",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.75rem",
          fontFamily: "Sfbold",
          fontWeight: "bold",
          letterSpacing: 1,
          background: `linear-gradient(45deg, ${gradientFirstColor}, ${gradientSecondColor})`,
          WebkitBackgroundClip: "text",
          color: "transparent", // Hide the original text
          display: "inline-block",
        }}
      >
        {data.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 1,
          transform: addTransition ? "translateY(0)" : "translateY(10px)",
          transition: `transform 0.05s ease-in`,
        }}
      >
        {[
          ...data.items.slice(newIndex, newIndex + 3),
          ...data.items.slice(
            0,
            Math.max(0, 3 - (data.items.length - newIndex))
          ),
        ].map((action, index) => (
          <Button
            key={index}
            onClick={() => {
              onClick(index);
              const description =
                selected && selectedIndex === index ? "" : action.description;
              onActionClick(description);
            }}
            sx={{
              height: "40px",
              borderRadius: "30px",
              background:
                selected && selectedIndex === index ? "#60a5fa" : "#f8fafc",
              color: selected && selectedIndex === index ? "#fff" : "#64748b",
              "&:hover": {
                background:
                  selected && selectedIndex === index ? "#60a5fa" : "#f1f5f9",
                color: selected && selectedIndex === index ? "#fff" : "#475569",
              },

              fontFamily: "Open Sans",
              fontSize: "15px",
              textTransform: "none",
              px: 3,

              transform: addTransition
                ? "translateY(0)"
                : `translateY(${(index + 1) * 50}px)`,
              transition: `transform 0.2s ease-in, background 0.05s ease-in, color 0.05s ease-in`,
            }}
          >
            {action.title.length > 23
              ? action.title.slice(0, 22) + "..."
              : action.title}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default CopilotSuggestionCard;
