import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import TextInputComponent from "../../localcomponents/TextInput";
// import EditIcon from "../../localcomponents/Icons/Edit";
import FileInput from "../../localcomponents/FileInput";

import { updatePatientsResource } from "../../localservices/patient.service";
import { isMobile } from "../../localservices/utility.service";
import PropTypes from "prop-types";

const DashboardTable = ({
  headers = [],
  datas = [[]],
  showDate = false,
  onClick,
  maxHeight = "auto",
  hasId = false,
  isRowClickable = false,
  isPatient = false,
  photo = [],
}) => {
  const [textColor, setTextColor] = useState("#000");
  const [rowId, setRowId] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [defaultProfilePic, setDefaultProfilePic] = useState("");

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      currentDate
    );
    const formattedDate = `${day} ${month}`;

    const year = currentDate.getFullYear();

    return { formattedDate, year };
  };

  const handlePhotoUpload = (photo) => {
    const reader = new FileReader();
    reader.readAsDataURL(photo);

    reader.onload = () => {
      const base64String = reader.result;
      setPatientData({
        ...patientData,
        Photo: [
          {
            contentType: photo?.type + "",
            data: base64String,
            title: "Profile Pic",
          },
        ],
      });
    };

    reader.onerror = (error) => {
      console.error("Error converting image to base64:", error);
    };
  };

  const handlePatientEditable = () => {
    let photo_str = patientData?.["Photo"]?.[0]?.["data"]?.split(",")[1];

    if (!isEditable) setIsEditable(true);
    else {
      const tempnewpatient = {
        resourceType: "Patient",
        active: true,
        id: patientData["ID"],
        name: [
          {
            given: [patientData["First Name"]],
            family: patientData["Last name"],
          },
        ],
        gender: patientData["Gender"],
        telecom: [
          {
            system: "email",
            value: patientData["Email"],
          },
          {
            system: "phone",
            value: patientData["Phone Number"],
          },
        ],
        address: [
          {
            use: "home",
            type: "both",
            line: [patientData["Street"]],
            city: patientData["City"],
            state: patientData["State"],
            postalCode: patientData["Postal Code"],
          },
        ],
      };

      const final = patientData?.["Photo"]?.[0]?.["data"]
        ? {
            ...tempnewpatient,
            photo: [
              {
                contentType: patientData?.Photo?.[0]?.contentType + "",
                data: photo_str,
                title: "Profile Pic",
              },
            ],
          }
        : tempnewpatient;
      updatePatientsResource(patientData["ID"], final)
        .then(() => {
          setIsEditable(false);
          setDefaultProfilePic(
            "data:" +
              patientData?.Photo?.[0]?.contentType +
              "" +
              ";base64," +
              photo_str
          );
        })
        .catch((e) => {
          console.log("err", e);
        });
    }
  };

  useEffect(() => {
    let pdata = {};
    if (isPatient && datas.length > 1) {
      for (let x = 0; x < datas.length; x++) {
        let k = datas[x][0];
        let v = datas[x][1];
        pdata[k + ""] = v;
      }

      let ctype = pdata?.["Photo"]?.[0]?.["contentType"];

      pdata["Photo"] = ctype
        ? [
            {
              contentType: ctype,
              data:
                "data:" + ctype + ";base64," + pdata?.["Photo"]?.[0]?.["data"],
              title: "Profile Pic",
            },
          ]
        : [];

      setPatientData(pdata);
    }
    if (photo) {
      const profileFtype = photo?.[0]?.["contentType"];
      const profileURL =
        "data:" + profileFtype + ";base64," + photo?.[0]?.["data"];
      setDefaultProfilePic(profileURL || "");
    }
  }, []);

  return (
    <>
      {isMobile() && isPatient ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            paddingBottom: 5,
          }}
        >
          {showDate && (
            <>
              <Box
                sx={{
                  background: "#F2F4F7",
                  height: "40px",
                  width: "100%",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  top: "0px",
                }}
              >
                <Box sx={{ border: "none" }}>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#000",
                    }}
                  >
                    {getCurrentDate().formattedDate}
                    <span
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#000",
                      }}
                    >
                      {getCurrentDate().year}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {isPatient && (
            <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
              {isEditable ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "none",
                      fontFamily: "Open Sans",
                      fontSize: "12px",
                      backgroundColor: "#1E5267",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#15404f",
                      },
                    }}
                    onClick={handlePatientEditable}
                  >
                    Update
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      textTransform: "none",
                      fontFamily: "Open Sans",
                      fontSize: "12px",
                      color: "#1E5267",
                      borderColor: "#1E5267",
                      "&:hover": {
                        backgroundColor: "#e6f2f7",
                        borderColor: "#15404f",
                      },
                    }}
                    onClick={() => setIsEditable(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    textTransform: "none",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    color: "#1E5267",
                    borderColor: "#1E5267",
                    "&:hover": {
                      backgroundColor: "#e6f2f7",
                      borderColor: "#15404f",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePatientEditable();
                  }}
                >
                  Edit
                </Button>
              )}
            </Box>
          )}
          {patientData?.["Photo"]?.[0]?.["data"] && (
            <img
              src={
                isEditable
                  ? patientData?.["Photo"]?.[0]?.["data"]
                  : defaultProfilePic
              }
              alt=""
              style={{
                width: "250px",
                height: "250px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          )}
          {!isPatient
            ? datas &&
              datas?.map((data, id) => (
                <Box
                  key={id}
                  sx={{
                    "&:hover": {
                      background: "#1E5267",
                      color: "#fff",
                    },
                    transition: "background-color 0.1s ease",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (isRowClickable) {
                      onClick(id);
                    }
                  }}
                >
                  {data.map((item, index) => {
                    if (hasId) {
                      return (
                        index !== 0 && (
                          <Box
                            onClick={() => {
                              onClick(data[0]);
                            }}
                            key={index}
                            sx={{
                              minWidth: "150px",
                              borderBottom: "none",
                              px: "6px",
                              borderRadius:
                                index === 0
                                  ? "8px 0 0 8px"
                                  : index === data.length - 1
                                  ? "0 8px 8px 0"
                                  : "0",
                              color: "inherit",
                            }}
                          >
                            <Box sx={{}}>
                              <Typography
                                sx={{
                                  fontFamily: "Open Sans",
                                  fontSize: "14px",
                                }}
                              >
                                {item}
                              </Typography>
                            </Box>
                          </Box>
                        )
                      );
                    } else {
                      return (
                        <Box
                          key={index}
                          sx={{
                            minWidth: "150px",
                            borderBottom: "none",
                            px: "6px",
                            borderRadius:
                              index === 0
                                ? "8px 0 0 8px"
                                : index === data.length - 1
                                ? "0 8px 8px 0"
                                : "0",
                            color: "inherit",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: "Open Sans",
                                fontSize: "14px",
                              }}
                            >
                              {item}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }
                  })}
                </Box>
              ))
            : datas &&
              datas?.map((edata, eid) => (
                <Box
                  key={eid}
                  sx={{
                    overflow: "hidden",
                  }}
                  onMouseOver={() => {
                    setRowId(eid);
                  }}
                  onMouseOut={() => {
                    setTextColor("#000");
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: "none",
                      px: "16px",
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: isEditable ? "column" : "row",
                        gap: 1,
                      }}
                    >
                      {edata[0] !== "Photo" && (
                        <Typography
                          sx={{
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            pt: 1,
                            minWidth: "150px",
                            color:
                              rowId === eid && !isEditable ? textColor : "#000",
                          }}
                        >
                          {edata[0]}
                        </Typography>
                      )}
                      <Box
                        sx={{
                          flexGrow: 1,
                        }}
                      >
                        {isEditable ? (
                          edata[0] == "Photo" ? (
                            <Box>
                              {patientData?.["Photo"]?.[0]?.["data"] && (
                                <img
                                  src={patientData?.["Photo"]?.[0]?.["data"]}
                                  alt=""
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                              <FileInput
                                bgColor="#f2f2f2"
                                textColor="#000"
                                uploadlabel="Upload photo"
                                acc="image/*"
                                handleChange={(e) => {
                                  handlePhotoUpload(e.target.files[0]);
                                }}
                                name={"upload-image-sample-profilepic"}
                              />
                            </Box>
                          ) : (
                            <TextInputComponent
                              size="small"
                              name={edata[0] + ""}
                              value={patientData[edata[0] + ""]}
                              handleChange={(e) => {
                                setPatientData({
                                  ...patientData,
                                  [edata[0] + ""]: e.target.value,
                                });
                              }}
                              disabled={edata[0] == "ID"}
                            />
                          )
                        ) : edata[0] == "Photo" ? (
                          <Box>
                            {/* <img
                              src={patientData?.[edata[0] + ""]?.[0]?.["data"]}
                              alt=""
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "contain",
                              }}
                            /> */}
                          </Box>
                        ) : (
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: "Open Sans",
                                fontSize: "14px",
                                pt: 1,
                                minWidth: "150px",
                                color: rowId === eid ? textColor : "#000",
                              }}
                            >
                              {patientData[edata[0] + ""]}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
        </Box>
      ) : (
        <Box sx={{ width: "100%", display: "flex", gap: 5 }}>
          {patientData?.["Photo"]?.[0]?.["data"] && (
            <img
              src={
                isEditable
                  ? patientData?.["Photo"]?.[0]?.["data"]
                  : defaultProfilePic
              }
              alt=""
              style={{
                width: "250px",
                height: "250px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          )}
          <TableContainer
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              background: "#fff",
              borderRadius: "10px",
              maxHeight: maxHeight,
              px: 1,
              pb: 1,
            }}
          >
            <Table sx={{ position: "relative" }}>
              <TableHead
                sx={{
                  borderBottom: "1px solid #EAECF0",
                  borderTop: "1px solid #EAECF0",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  background: "#fff",
                }}
              >
                <TableRow sx={{ height: "40px" }}>
                  {headers.map((header, index) => {
                    return (
                      <TableCell key={index} sx={{ py: 0 }}>
                        <Typography
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {header}
                        </Typography>
                      </TableCell>
                    );
                  })}
                  {isPatient && (
                    <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
                      {isEditable ? (
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              textTransform: "none",
                              fontFamily: "Open Sans",
                              fontSize: "12px",
                              backgroundColor: "#1E5267",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#15404f",
                              },
                            }}
                            onClick={handlePatientEditable}
                          >
                            Update
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              textTransform: "none",
                              fontFamily: "Open Sans",
                              fontSize: "12px",
                              color: "#1E5267",
                              borderColor: "#1E5267",
                              "&:hover": {
                                backgroundColor: "#e6f2f7",
                                borderColor: "#15404f",
                              },
                            }}
                            onClick={() => setIsEditable(false)}
                          >
                            Cancel
                          </Button>
                        </Box>
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            textTransform: "none",
                            fontFamily: "Open Sans",
                            fontSize: "12px",
                            color: "#1E5267",
                            borderColor: "#1E5267",
                            "&:hover": {
                              backgroundColor: "#e6f2f7",
                              borderColor: "#15404f",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePatientEditable();
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  )}
                </TableRow>
              </TableHead>
              <TableBody sx={{ overflowY: "auto" }}>
                {showDate && (
                  <>
                    <TableRow
                      sx={{
                        background: "#F2F4F7",
                        height: "40px",
                        width: "100%",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        position: "absolute",
                        top: "0px",
                      }}
                    >
                      <TableCell sx={{ border: "none" }}>
                        <Typography
                          sx={{
                            fontFamily: "Open Sans",
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#000",
                          }}
                        >
                          {getCurrentDate().formattedDate}
                          <span
                            style={{
                              fontFamily: "Open Sans",
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#000",
                            }}
                          >
                            {getCurrentDate().year}
                          </span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {!isPatient
                  ? datas &&
                    datas?.map((data, id) => (
                      <TableRow
                        key={id}
                        sx={{
                          "&:hover": {
                            background: "#1E5267",
                            color: "#fff",
                          },
                          transition: "background-color 0.1s ease",
                          overflow: "hidden",
                          height: "40px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (isRowClickable) {
                            onClick(id);
                          }
                        }}
                      >
                        {data.map((item, index) => {
                          if (hasId) {
                            return (
                              index !== 0 && (
                                <TableCell
                                  onClick={() => {
                                    onClick(data[0]);
                                  }}
                                  key={index}
                                  sx={{
                                    minWidth: "150px",
                                    borderBottom: "none",
                                    px: "16px",
                                    borderRadius:
                                      index === 0
                                        ? "8px 0 0 8px"
                                        : index === data.length - 1
                                        ? "0 8px 8px 0"
                                        : "0",
                                    color: "inherit",
                                  }}
                                >
                                  <Box sx={{}}>
                                    <Typography
                                      sx={{
                                        fontFamily: "Open Sans",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              )
                            );
                          } else {
                            return (
                              <TableCell
                                key={index}
                                sx={{
                                  minWidth: "150px",
                                  borderBottom: "none",
                                  px: "16px",
                                  borderRadius:
                                    index === 0
                                      ? "8px 0 0 8px"
                                      : index === data.length - 1
                                      ? "0 8px 8px 0"
                                      : "0",
                                  color: "inherit",
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      fontFamily: "Open Sans",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                </Box>
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    ))
                  : datas &&
                    datas?.map((edata, eid) => (
                      <TableRow
                        key={eid}
                        sx={{
                          overflow: "hidden",
                          height: "40px",
                        }}
                        onMouseOver={() => {
                          setRowId(eid);
                        }}
                        onMouseOut={() => {
                          setTextColor("#000");
                        }}
                      >
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            px: "16px",
                            borderRadius: "8px",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            {edata[0] !== "Photo" && (
                              <Typography
                                sx={{
                                  fontFamily: "Open Sans",
                                  fontSize: "14px",
                                  pt: 1,
                                  minWidth: "150px",
                                  color:
                                    rowId === eid && !isEditable
                                      ? textColor
                                      : "#000",
                                }}
                              >
                                {edata[0]}
                              </Typography>
                            )}
                            <Box
                              sx={{
                                flexGrow: 1,
                              }}
                            >
                              {isEditable ? (
                                edata[0] == "Photo" ? (
                                  <Box>
                                    {patientData?.["Photo"]?.[0]?.["data"] && (
                                      <img
                                        src={
                                          patientData?.["Photo"]?.[0]?.["data"]
                                        }
                                        alt=""
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    )}
                                    <FileInput
                                      bgColor="#f2f2f2"
                                      textColor="#000"
                                      uploadlabel="Upload photo"
                                      acc="image/*"
                                      handleChange={(e) => {
                                        handlePhotoUpload(e.target.files[0]);
                                      }}
                                      name={"upload-image-sample-profilepic"}
                                    />
                                  </Box>
                                ) : (
                                  <TextInputComponent
                                    size="small"
                                    name={edata[0] + ""}
                                    value={patientData[edata[0] + ""]}
                                    handleChange={(e) => {
                                      setPatientData({
                                        ...patientData,
                                        [edata[0] + ""]: e.target.value,
                                      });
                                    }}
                                    disabled={edata[0] == "ID"}
                                  />
                                )
                              ) : edata[0] == "Photo" ? (
                                <Box>
                                  {/* <img
                                    src={
                                      patientData?.[edata[0] + ""]?.[0]?.[
                                        "data"
                                      ]
                                    }
                                    alt=""
                                    style={{ width: "100px", height: "100px" }}
                                  /> */}
                                </Box>
                              ) : (
                                <Box>
                                  <Typography
                                    sx={{
                                      fontFamily: "Open Sans",
                                      fontSize: "14px",
                                      pt: 1,
                                      minWidth: "150px",
                                      color: rowId === eid ? textColor : "#000",
                                    }}
                                  >
                                    {patientData[edata[0] + ""]}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </Box>
      )}
    </>
  );
};

DashboardTable.propTypes = {
  headers: PropTypes.array,
  datas: PropTypes.array,
  showDate: PropTypes.bool,
  onClick: PropTypes.func,
  maxHeight: PropTypes.any,
  hasId: PropTypes.bool,
  isRowClickable: PropTypes.bool,
  isPatient: PropTypes.bool,
};
export default DashboardTable;
