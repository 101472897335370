import HttpService from "./http.service";
import { topicURL, courseContentURL } from "./url.service";

export const updateTopicContent = (top_id, cont_id, cont_data) => {
  return HttpService.put(
    courseContentURL + "/" + cont_id + "?topic_id=" + top_id,
    cont_data
  );
};

export const getTopicContent = (top_id) => {
  return HttpService.get(topicURL + "/" + top_id + "/content", {});
};

export const createIndependantTopic = (topicdata) => {
  return HttpService.post(topicURL + "/", topicdata);
};

export const createTopicContent = (top_id, content) => {
  return HttpService.post(topicURL + "/" + top_id + "/content", content);
};
export const isMobile = () => {
  return window.innerWidth <= 900;
};
