import axios from "axios";

const http = axios.create();

http.interceptors.request.use((config) => {
  const token = `Bearer ${localStorage.getItem("usertoken")}` || "";
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

http.interceptors.response.use(
  (result) => {
    return result;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (error.response.status == 401 || error.response.status == 403) {
        // localStorage.clear();
        // window.location.href = "/login";
      }
      // do something
    } else {
      // do something else
    }
  }
);

const post = (url, data, params = {}) => {
  // const header = {
  //   Authorization: `Bearer token`,
  // };

  return http
    .post(url, data, {
      ...params,
    })
    .catch((err) => {
      console.log(err.response);
    });
};

const multi_part_put = (url, data, params = {}) => {
  const header = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer token`,
  };

  return axios.put(url, data, { ...params, headers: header });
};

const multi_part_post = (url, data, params = {}) => {
  const header = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer token`,
  };

  return axios.post(url, data, { ...params, headers: header });
};

const get = (url, queries, params = {}) => {
  // const header = {
  //   Authorization: `Bearer token`,
  // };
  return http.get(url, {
    params: queries,
    ...params,
  });
};

const put = (url, data, params = {}) => {
  const header = {
    Authorization: `Bearer token`,
  };
  return http.put(url, data, {
    ...params,
    headers: header,
  });
};

const remove = (url, data) => {
  const header = {
    Authorization: `Bearer token`,
  };
  return http.delete(url, {
    headers: header,
    data,
  });
};

const HttpService = {
  http,
  get,
  post,
  multi_part_post,
  put,
  multi_part_put,
  remove,
};

export default HttpService;
