import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dotA: {
    width: 6,
    height: 6,
    backgroundColor: "#FF6413",
    borderRadius: "50%",
    margin: "0 4px",
    animation: "$bounce 1s infinite",
  },
  dotB: {
    width: 6,
    height: 6,
    backgroundColor: "#31c3d4",
    borderRadius: "50%",
    margin: "0 4px",
    animation: "$bounce 1s infinite",
  },
  dotC: {
    width: 6,
    height: 6,
    backgroundColor: "#FF6413",
    borderRadius: "50%",
    margin: "0 4px",
    animation: "$bounce 1s infinite",
  },
  dot1: {
    animationDelay: "0s",
  },
  dot2: {
    animationDelay: "0.2s",
  },
  dot3: {
    animationDelay: "0.4s",
  },
  "@keyframes bounce": {
    "0%, 80%, 100%": {
      transform: "scale(0)",
    },
    "40%": {
      transform: "scale(1)",
    },
  },
}));

const DotLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingContainer}>
      <div className={`${classes.dotA} ${classes.dot1}`} />
      <div className={`${classes.dotB} ${classes.dot2}`} />
      <div className={`${classes.dotC} ${classes.dot3}`} />
    </div>
  );
};

export default DotLoading;
