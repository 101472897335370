import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { CircularProgress } from "@mui/material";

// import AuthenticatedRoute from "@/components/AuthenticatedRoute";

import AuthRoute from "@/components/AuthRoute";
// import SidebarContext from "@/context/SidebarContext";
// import SecurityContext from "@/context/SecurityContext";
// import { UserContextProvider } from "@/context/UserContext";
// import { ResourceContextProvider } from "@/context/ResourceContext";
// import FloatingView from "../home/AutoPilotPage";
// import NavBar from "@/components/NavBar";
// import Sidebar from "@/components/Sidebar";

import "./index.scss";

// import HomeContainer from "../../containers/HomeContainer.js";

const LoginView = lazy(() => import("../auth/LoginView"));
const ForgotPassword = lazy(() => import("../auth/ForgotPassword"));
const DemoView = lazy(() => import("../auth/DemoView"));
// const LandingView = lazy(() => import("../auth/LandingView"));
const Landing = lazy(() => import("../auth/Landing"));
const Microbiome = lazy(() => import("../auth/Microbiome"));
const RegisterView = lazy(() => import("../auth/RegisterView"));

const RequestInviteView = lazy(() => import("../auth/RequestInviteView"));
const VerifyAccountView = lazy(() => import("../auth/VerifyAccountView"));
const DashboardView = lazy(() => import("../home/DashboardPage"));
const AppointmentView = lazy(() => import("../home/AppointmentPage"));
const NewAppointmentView = lazy(() => import("../home/NewAppointment"));
const AppointmentDetailView = lazy(() => import("../home/AppointmentDetail"));
const AddObservationView = lazy(() => import("../home/AddObservation"));
const AddServiceReqView = lazy(() => import("../home/AddServiceReq"));
const ServiceRequestsView = lazy(() => import("../home/ServiceRequests"));
const EncounterListPageView = lazy(() => import("../home/EncounterListPage"));
const EncounterPageView = lazy(() => import("../home/EncounterPage"));
const SettingsPageView = lazy(() => import("../home/SettingsPage"));

const PatientsPageView = lazy(() => import("../patients/PatientsPage"));
const PractionersPageView = lazy(() => import("../patients/PractitionersPage"));
const PatientDetailsView = lazy(() => import("../patients/PatientDetails"));
const PatientReportFileView = lazy(
  () => import("../patients/PatientReportFile")
);
const PractitionersDetail = lazy(
  () => import("../patients/PractitionersDetail")
);
const AddNewPatientView = lazy(() => import("../patients/AddNewPatient"));
const AddNewPractitioner = lazy(() => import("../patients/AddNewPractitioner"));

const MeetingPageView = lazy(() => import("../home/MeetingPage"));
const MeetingStartView = lazy(() => import("../home/MeetingStart"));
const OrganizationView = lazy(() => import("../home/Organization"));

const WorkflowDetailView = lazy(() => import("../workflows/WorkflowDetail"));
const WorkflowProgressView = lazy(
  () => import("../workflows/WorkflowProgressView")
);
const WorkflowsPageView = lazy(() => import("../workflows/WorkflowsPage"));
const SamplesView = lazy(() => import("../workflows/CreateSamplePage"));
const XoncoPromptView = lazy(() => import("../workflows/XoncoPromptPage"));

const AddTaskView = lazy(() => import("../task/AddTask"));
const UpdateTaskView = lazy(() => import("../task/UpdateTask"));
const TasksView = lazy(() => import("../task/TaskList"));

// const TermsView = lazy(() => import("../TermsView"));
const ReportsView = lazy(() => import("../ReportsView"));
const ReportView = lazy(() => import("../ReportView"));
const PrintView = lazy(() => import("../PrintView"));
// const GermlineView = lazy(() => import("../GermlineView"));
// const AdminView = lazy(() => import("../AdminView"));
// const LinkOutView = lazy(() => import("../LinkOutView"));
const PatientsView = lazy(() => import("../PatientsView"));

// const TemplateView = lazy(() => import("../TemplateView"));
// const ProjectsView = lazy(() => import("../ProjectsView"));

/**
 * Entry point to application. Handles routing, app theme, and logged in state.
 */
const Main = (): JSX.Element => {
  // const [authorizationToken, setAuthorizationToken] = useState("");
  // const [userDetails, setUserDetails] = useState("");
  const sidebarMaximized = false;
  // const [isNavVisible, setIsNavVisible] = useState(true);
  // const locationt = useLocation().pathname;

  // const secContextVal = useMemo(
  //   () => ({
  //     authorizationToken,
  //     setAuthorizationToken,
  //     userDetails,
  //     setUserDetails,
  //   }),
  //   [authorizationToken, setAuthorizationToken, userDetails, setUserDetails]
  // );

  // const sideBarContextVal = useMemo(
  //   () => ({
  //     sidebarMaximized,
  //     setSidebarMaximized,
  //   }),
  //   [sidebarMaximized, setSidebarMaximized]
  // );

  useEffect(() => {
    const ratio = window.devicePixelRatio || 1;
    const w = screen.width * ratio;

    if (w < 1000) {
      localStorage.setItem("diagViewMode", "grid");
      localStorage.setItem("tasksViewMode", "grid");
      localStorage.setItem("practViewMode", "grid");
      localStorage.setItem("patientsViewMode", "grid");
      localStorage.setItem("encViewMode", "grid");
      localStorage.setItem("appViewMode", "grid");
    }
  }, []);

  return (
    // <SecurityContext.Provider value={secContextVal}>
    //   <UserContextProvider>
    //     <ResourceContextProvider>
    //       <SidebarContext.Provider value={sideBarContextVal}>
    <div>
      <section
        className={`main__content ${
          sidebarMaximized ? "main__content--maximized" : ""
        }`}
      >
        {/* {locationt?.includes("/app") ? (
                  <div style={{ position:"absolute", right:"10px", top:"70px" }}>
                    <div style={{ position:"relative", zIndex:"1200" }}>
                      <FloatingView />
                    </div>
                  </div>
                ) : null} */}
        <Suspense
          fallback={
            <div
              style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          }
        >
          <Switch>
            {/* <Route component={LoginView} path="/login" /> */}
            {/* <Route component={LinkOutView} path="/graphkb" /> */}
            <Route path="/" exact>
              <Redirect to={{ pathname: "/home" }} />
            </Route>

            {/* <Route component={LandingView} path="/home" /> */}
            <Route component={Landing} path="/home" />
            <Route component={Microbiome} path="/microbiome" />
            <Route component={LoginView} path="/login" />
            <Route component={ForgotPassword} path="/reset_password" />
            <Route component={DemoView} path="/demo" />
            <Route component={RegisterView} path="/register" />
            <Route component={RequestInviteView} path="/requestinvite" />
            <Route component={VerifyAccountView} path="/verify_account" />

            <AuthRoute component={DashboardView} path="/app/dashboard" />
            <AuthRoute component={AppointmentView} path="/app/appointments" />
            <AuthRoute
              component={AppointmentDetailView}
              path="/app/appointments/:id"
            />
            <AuthRoute
              component={NewAppointmentView}
              path="/app/newAppointment"
            />
            <AuthRoute component={ReportsView} path="/reports" />
            <AuthRoute component={ReportView} path="/report/:ident" />

            <Route
              exact
              component={PatientsView}
              path="/reports/patients/:patientId"
            />
            <Route component={PrintView} path="/print/:ident" />
            <Redirect
              exact
              from="/report/:ident/(genomic|probe)/summary"
              to="/report/:ident/summary"
            />

            <AuthRoute
              component={AddServiceReqView}
              path="/app/addservicerequest"
            />
            <AuthRoute
              component={ServiceRequestsView}
              path="/app/ServiceRequests"
            />
            <AuthRoute component={AddObservationView} path="/app/observation" />
            <AuthRoute
              component={EncounterListPageView}
              path="/app/encounter"
            />
            <AuthRoute component={SettingsPageView} path="/app/settings" />
            <AuthRoute component={EncounterPageView} path="/app/addencounter" />
            <AuthRoute component={AddNewPatientView} path="/app/patients/new" />
            <AuthRoute
              component={AddNewPractitioner}
              path="/app/practitioners/new"
            />
            <AuthRoute
              component={PatientDetailsView}
              path="/app/patients/:id"
            />
            <AuthRoute
              component={PatientReportFileView}
              path="/app/patientreport/viewfile"
            />
            <AuthRoute
              component={PractitionersDetail}
              path="/app/practitioners/:id"
            />
            <AuthRoute component={PatientsPageView} path="/app/patients" />
            <AuthRoute
              component={PractionersPageView}
              path="/app/practitioners"
            />
            <AuthRoute component={MeetingPageView} path="/app/meet/:id" />
            <AuthRoute component={MeetingStartView} path="/app/meet" />
            <AuthRoute component={OrganizationView} path="/app/organization" />
            <AuthRoute
              component={WorkflowDetailView}
              path="/app/workflows/:id/:referer"
            />
            <AuthRoute
              component={WorkflowProgressView}
              path="/app/workflowprogress/:id"
            />
            <AuthRoute component={WorkflowsPageView} path="/app/workflows" />
            <AuthRoute component={AddTaskView} path="/app/task" />
            <AuthRoute component={UpdateTaskView} path="/app/updatetask" />
            <AuthRoute component={TasksView} path="/app/tasks" />
            <AuthRoute component={SamplesView} path="/app/samples" />
            <AuthRoute component={XoncoPromptView} path="/app/oncoprompt" />

            {/* <AuthenticatedRoute component={TermsView} path="/terms" /> */}
            {/* <AuthenticatedRoute component={ReportsView} path="/reports" /> */}
            {/* <AuthenticatedRoute
                      exact
                      component={PatientsView}
                      path="/reports/patients/:patientId"
                    /> */}
            {/* <AuthenticatedRoute
                      component={ReportView}
                      path="/report/:ident"
                    /> */}
            {/* <AuthenticatedRoute
                      component={PrintView}
                      path="/print/:ident"
                      showNav={false}
                      onToggleNav={setIsNavVisible}
                    /> */}
            {/* <AuthenticatedRoute
                      component={GermlineView}
                      path="/germline"
                    /> */}
            {/* <AuthenticatedRoute
                      component={ProjectsView}
                      path="/projects"
                    /> */}
            {/* <AuthenticatedRoute
                      adminRequired
                      component={AdminView}
                      path="/admin"
                    /> */}
            {/* <AuthenticatedRoute
                      adminRequired
                      component={TemplateView}
                      path="/template"
                    /> */}
          </Switch>
        </Suspense>
      </section>
    </div>
    //       </SidebarContext.Provider>
    //     </ResourceContextProvider>
    //   </UserContextProvider>
    // </SecurityContext.Provider>
  );
};

export default Main;
