import React, { useState, useRef, useEffect } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  Box,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom"; // Import useLocation
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Schedule } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import BiotechIcon from "@mui/icons-material/Biotech";
import AccessibleIcon from "@mui/icons-material/Accessible";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import VideoChatIcon from "@mui/icons-material/VideoCall";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { getCurrentUserDetail } from "../../localservices/auth.service";

const BottomNav = () => {
  const [value, setValue] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeMoreIndex, setActiveMoreIndex] = useState(null);
  const drawerRef = useRef(null);
  const history = useHistory();
  const location = useLocation(); // Get the current location

  const handleOpenDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setIsDrawerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const user = localStorage.getItem("username");
  const [userInfo, setUserInfo] = React.useState({
    fullName: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    getCurrentUserDetail()
      .then((res) => {
        let current_user = res.data;

        setUserInfo({
          fullName:
            current_user?.first_name + " " + current_user?.last_name + "",
          email: current_user?.email,
          phone: current_user?.phone,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const mainMenuItems = [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      path: "/app/dashboard",
    },
    {
      name: "Patients",
      icon: <AccessibleIcon />,
      path: "/app/patients",
    },
    {
      name: "Diagnostics",
      icon: <BiotechIcon />,
      path: "/app/workflows",
    },
  ];

  const moreMenuItems = [
    {
      name: "Appointment",
      icon: <Schedule />,
      path: "/app/appointments",
    },
    {
      name: "Practitioners",
      icon: <PersonAddAlt1Icon />,
      path: "/app/practitioners",
    },
    {
      name: "Encounter",
      icon: <PeopleOutlineIcon />,
      path: "/app/encounter",
    },
    {
      name: "Tasks",
      icon: <TaskOutlinedIcon />,
      path: "/app/tasks",
    },
    {
      name: "Report",
      icon: <ListAltOutlinedIcon />,
      path: "/reports",
    },
    {
      name: "Expert Panel",
      icon: <VideoChatIcon />,
      path: "/app/meet",
    },
    {
      name: "Organization",
      icon: <CorporateFareIcon />,
      path: "/app/organization",
    },
    {
      name: "Settings",
      icon: <SettingsIcon />,
      path: "/app/settings",
    },
    {
      name: "Logout",
      icon: <LogoutIcon />,
      path: "/login",
    },
  ];

  const handleNavigation = (path, index) => {
    history.push(path);
    setIsDrawerOpen(false);
    setActiveMoreIndex(index); // Set the active index
  };

  // Set the activeMoreIndex based on the current location
  useEffect(() => {
    const currentPath = location.pathname;

    // Check main menu items first
    const mainIndex = mainMenuItems.findIndex(
      (item) => item.path === currentPath
    );
    if (mainIndex !== -1) {
      setValue(mainIndex);
      setActiveMoreIndex(null); // Reset activeMoreIndex when in main menu
    } else {
      // Check more menu items
      const moreIndex = moreMenuItems.findIndex(
        (item) => item.path === currentPath
      );
      if (moreIndex !== -1) {
        setActiveMoreIndex(moreIndex);
      } else {
        // setValue(0); // Default to first tab if no match
        // setActiveMoreIndex(null); // Reset activeMoreIndex when path doesn't match
      }
    }
  }, [location.pathname]); // Run effect on path change

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        boxShadow: 3,
        height: "70px",
        display: { xs: "flex", md: "none" },
        background: "#fff",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        showLabels
      >
        {mainMenuItems.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={item.name}
            icon={item.icon}
            onClick={() => handleNavigation(item.path, index)}
          />
        ))}
        <BottomNavigationAction
          label="More"
          icon={<MoreVertIcon />}
          onClick={handleOpenDrawer}
        />
      </BottomNavigation>

      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            height: "50%",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            padding: 2,
          },
        }}
      >
        <Box ref={drawerRef}>
          <List>
            {moreMenuItems.map((item, index) => (
              <ListItemButton
                key={index}
                selected={activeMoreIndex === index} // Highlight active item
                onClick={() => handleNavigation(item.path, index)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            ))}

            <Box sx={{ display: "flex", gap: "12px", padding: 2 }}>
              <IconButton
                sx={{
                  border: "3px solid #94a3b8",
                  width: "42px",
                  height: "42px",
                }}
              >
                <PersonRoundedIcon size={42} sx={{ color: "#94a3b8" }} />
              </IconButton>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontWeight: "700",
                      color: "#344054",
                      fontSize: "14px",
                    }}
                  >
                    {userInfo?.fullName}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                      color: "#667085",
                      fontSize: "14px",
                    }}
                  >
                    {user}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default BottomNav;
