import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const Input = styled("input")({
  display: "none",
});

const FileInput = ({
  uploadlabel = "Upload",
  textColor = "#000",
  bgColor = "#fff",
  name = "na",
  handleChange,
  disabled = false,
  width = "100%",
  acc = "*",
}) => {
  return (
    <div>
      <label htmlFor={"contained-button-file" + name}>
        <Input
          disabled={disabled}
          accept={acc + ""}
          id={"contained-button-file" + name}
          multiple
          type="file"
          onChange={handleChange}
        />
        <Button
          variant="contained"
          component="span"
          fullWidth
          style={{
            backgroundColor: bgColor,
            color: textColor,
            borderRadius: "8px",
            boxShadow: "none",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: "600",
            padding: "8px 30px",
            width: width,
            lineHeight: 1.5,
            fontFamily: "Open Sans",
          }}
        >
          {uploadlabel}
        </Button>
      </label>
    </div>
  );
};

export default FileInput;
