import "./publicPath";
import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";

import "regenerator-runtime/runtime.js";

import App from "./App";
import { unregister } from "./registerServiceWorker";
import("./utils/signozHelper").then(() => {
    console.log("🔵 OpenTelemetry has been initialized");
  });
ReactDOM.render(<App />, document.getElementById("base"));
unregister();
