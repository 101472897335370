// edjsHTML tranforms editor js blocks to html
// import edjsHTML from "editorjs-html";
// this function parses strings (html elements) to html
// import parse from "html-react-parser";
// const edjsParser = edjsHTML();

import edjsParser from "editorjs-parser";
import React from "react";
import "../index.css";

const customParsers = {
  linkTool: function (data, config) {
    // const cfg = config?.linkTool // configurations for linkTool
    // Display meta tags if available (title, description)
    const imageLink = data?.meta?.image.url || data?.meta?.image.url || "";
    let imageDiv = "";
    if (imageLink?.length > 0) {
      imageDiv = `<div>
      <img src="${imageLink}" width:"50px" height="50px" />
      </div>`;
    }
    return `                            
      <a href="${data.link}" target="_blank" style="text-decoration:none;">
        <div style="display:flex; border: 1px solid #ccc; border-radius:7px; padding:15px; background-color:#fff; margin-bottom:10px;">
          <div style="margin-right:10px">
            ${
              data?.meta?.title?.length > 0
                ? '<h3 style="color:#404040">' + data.meta.title + "</h3>"
                : ""
            }       
            ${
              data?.meta?.description?.length > 0
                ? '<p style="margin-top:10px; font-size:16px; color:#404040;">' +
                  data.meta.description +
                  "</p>"
                : ""
            }       
            ${
              data?.link
                ? '<p style="margin-top:10px; font-size:16px; color:#ccc;">' +
                  data.link +
                  "</p>"
                : ""
            }         
          </div>
          <div style="margin-top:20px;">${imageDiv}</div>
        </div>   
      </a>`;
  },
  table: function (data) {
    const tableHeadings = data.withHeadings
      ? `<thead>
          <tr>${data.content[0]
            .map(
              (cell) =>
                `<th style="padding: 8px; border: 1px solid #ddd;">${cell}</th>`
            )
            .join("")}</tr>
        </thead>`
      : "";
    const tableBody = data.content
      .slice(data.withHeadings ? 1 : 0)
      .map(
        (row) =>
          `<tr>${row
            .map(
              (cell) =>
                `<td style="padding: 8px; border: 1px solid #ddd;">${cell}</td>`
            )
            .join("")}</tr>`
      )
      .join("");

    return `
      <table style="min-width:50%; border-collapse: collapse; margin-bottom: 12px;">
        ${tableHeadings}
        <tbody>
          ${tableBody}
        </tbody>
      </table>`;
  },
};
const parser = new edjsParser({
  paragraph: {
    pClass: "parserparagraph", // used class for paragraph tags
  },
  embed: {
    useProvidedLength: true,
    width: "100%",
    // set to true if you want the returned width and height of editorjs to be applied
    // NOTE: sometimes source site overrides the lengths so it does not work 100%
  },
  quote: {
    applyAlignment: false,
    // if set to true blockquote element will have text-align css property set
  },
  customParsers,
});

export default function EditorTextParser({ data, expanded }) {
  let width = expanded ? "655px" : "270px";
  let height = expanded ? "370px" : "150px";
  let maxWidth = expanded ? "100%" : "100%";
  let fontSize = expanded ? "18px" : "16px";
  let imgBorder = expanded ? "15px" : "10px";
  let imgMT = expanded ? "25px" : "15px";
  let textColor = "#000";

  // array of HTML elements
  let markup = parser.parse(data);

  // Replace the width and height attributes in the iframe tag
  markup = markup.replace(/<iframe[^>]*>/, (match) => {
    // Replace the width and height attributes with variables
    const updatedIframe = match.replace(/width="[^"]*"/, `width="${width}"`);
    return updatedIframe.replace(/height="[^"]*"/, `height="${height}"`);
  });

  // Add margin: 0 style to <figure> elements
  markup = markup.replace(/<figure[^>]*>/g, (match) => {
    if (!match.includes("style")) {
      // Add style attribute for margin if it's missing
      return `<figure style="margin: 0;"${match}`;
    } else {
      return match;
    }
  });

  markup = markup.replace(/<img/g, (match) => {
    if (!match.includes("style")) {
      // Add style attribute for width if it's missing
      return `<img style="width: ${width}; border-radius:${imgBorder}; margin-top:${imgMT}"${match}`;
    } else {
      return match;
    }
  });
  markup = markup.replace(/<p/g, (match) => {
    if (!match.includes("style")) {
      // Add style attribute for width if it's missing
      return `<p style="max-width: ${maxWidth};font-size:${fontSize}; font-family:'Open Sans'; color:${textColor} " ${match}`;
    } else {
      return match;
    }
  });

  function createMarkup() {
    return { __html: markup };
  }

  return (
    <div
      className="text-container-edjs"
      style={{
        width: "100%",
        fontFamily: "Open Sans",
        background: "white",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      dangerouslySetInnerHTML={createMarkup()}
    />
  );
}
