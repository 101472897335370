import React from "react";
import PropTypes from "prop-types";
// import ListItem from "@mui/material/ListItem";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import DisplayText from "./DisplayText";
import "../index.css";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";

function SideBarListItem({
  content = "",
  icon = <></>,
  active = false,
  isExpanded,
}) {
  return (
    <Box
      sx={{
        background: active ? "#1E5267" : "",
        width: "85%",
        mx: "auto",
        borderRadius: "8px",
        "&:hover": {
          background: active ? "#1E5267" : "#00000008",
        },
        height: "50px",
        display: "flex",
        alignItems: "Center",
        justifyContent: isExpanded ? "start" : "center",
        gap: isExpanded ? 0.5 : 0,
        pl: isExpanded ? 1 : 0,
        cursor: "pointer",
      }}
    >
      <Tooltip title={content + ""} placement="right-start">
        <IconButton>
          {React.cloneElement(icon, {
            sx: { color: active ? "#fff" : "#9D9FA9" },
          })}
        </IconButton>
      </Tooltip>
      {isExpanded && (
        <Typography
          sx={{
            color: active ? "#fff" : "#344054",
            fontFamily: "Open Sans",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          {content}
        </Typography>
      )}
    </Box>
  );
}

SideBarListItem.propTypes = {
  content: PropTypes.string,
  icon: PropTypes.element,
  active: PropTypes.bool,
};

export default SideBarListItem;
