import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import "../index.css";

const useStyles = makeStyles(({}) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      display: "flex",
      alignItems: "end",
      FontFamily: "Open Sans",
      fontWeight: "500",
      borderRadius: "8px",
      fontSize: "15px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#eef2f6",
      border: "1.5px solid #eef2f6",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#eef2f6",
      border: "1.5px solid #eef2f6",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#eef2f6",
      border: "1.5px solid #eef2f6",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336", // Red color for error
    },
  },
  input: {
    borderRadius: "20px",
  },
}));

function TextInputComponent({
  placeholder = "",
  value = "",
  name = "",
  handleChange = () => {},
  handleKeyPress = () => {},
  rows = 1,
  borderRD = false,
  type = "text",
  endIcon = "",
  multiline = false,
  size = "small",
  disabled = false,
  bgColor = "#fff",
  error = "",
  helperText = "",
}) {
  console.log(error, helperText);
  const classes = useStyles();

  return (
    <TextField
      name={name}
      fullWidth
      className={classes.root}
      variant="outlined"
      placeholder={placeholder}
      multiline={multiline}
      rows={multiline ? "" : rows}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      size={size}
      style={{
        backgroundColor: bgColor,
        fontFamily: "Open Sans",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ height: "100%" }}>
            {endIcon}
          </InputAdornment>
        ),
        classes: {
          root: borderRD ? classes.input : "",
        },
        inputProps: {
          type: type,
        },
      }}
      error={!!error}
      helperText={helperText}
    />
  );
}

TextInputComponent.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  rows: PropTypes.number,
  borderRD: PropTypes.bool,
};

export default TextInputComponent;
