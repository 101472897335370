export const baseURL = process.env.REACT_APP_BASE_URL;
export const drxnaUrl = process.env.REACT_APP_DRXNA_URL;
export const xflowURL = process.env.REACT_APP_XFLOW_URL;
export const meetURL = process.env.REACT_APP_MEET_URL;
// export const contentURL = process.env.REACT_APP_CONTENT_URL;
export const contentURL = drxnaUrl + "/xnacircle/";
export const xaiBaseURL = process.env.REACT_APP_XAI_URL;

export const loginURL = baseURL + "login";
export const registerURL = baseURL + "register";
export const inviteURL = baseURL + "request_invite";
export const verifyAccountURL = baseURL + "verify_account";
export const userURL = baseURL + "get_user";
export const requestResetPasswordURL = baseURL + "reset_password_request";
export const resetPasswordURL = baseURL + "reset_password/";

export const deleteLotURL = drxnaUrl + "/samplelot/";
export const updateLotURL = drxnaUrl + "/samplelot/";
export const removeSampleURL = drxnaUrl + "/sample/";
export const getASampleURL = drxnaUrl + "/sample/";
export const updateSampleURL = drxnaUrl + "/sample/";
export const patientURL = drxnaUrl + "/patients";
export const appointmentURL = drxnaUrl + "/appointments";
export const fhirURL = drxnaUrl + "/fhir/";
export const shipmentURL = drxnaUrl + "/shipment";
export const drxnaOrgs = drxnaUrl + "/organization/";
export const sampleProfileUrl = drxnaUrl + "/sampleprofile/";
export const uploadURL = drxnaUrl + "/media/";

export const userContentURL = contentURL + "user";
export const courseContentURL = contentURL + "content";
export const topicURL = contentURL + "topic";

export const appointmentDatesURL = appointmentURL + "/dates";
export const suggestionUrl = xaiBaseURL + "/copilot/chat/suggestions";
