import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import DisplayText from "../../localcomponents/DisplayText";
import PropTypes from "prop-types";

const MeetingTable = ({
  data,
  height = "500px",
  dashboard = false,
  handleClick,
}) => {
  const [textColor, setTextColor] = useState("#000");
  const [rowId, setRowId] = useState(0);
  const [childRowId, setChildRowId] = useState(0);

  const colorCodes = ["#2BA1A1", "#0435EA", "#DD00A0", "#F2F4F7"];

  function capitalizeFirstTwoLetters(inputString) {
    if (typeof inputString !== "string" || inputString.length < 2) {
      return inputString;
    }

    const firstTwoLetters = inputString.substring(0, 2).toUpperCase();

    // const result = firstTwoLetters + inputString.substring(2);

    return firstTwoLetters;
  }
  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);

    if (isNaN(dateObject.getTime())) {
      return "Invalid date";
    }
    const day = dateObject.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      dateObject
    );
    const year = dateObject.getFullYear();

    // Construct the formatted date
    const formattedDate = `${day} ${month}`;

    return { formattedDate, year };
  }
  function extractTime(inputDateString) {
    const dateObject = new Date(inputDateString);

    if (isNaN(dateObject.getTime())) {
      return "Invalid date";
    }

    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;

    // Format the time
    const formattedTime = `${formattedHours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;

    return formattedTime;
  }
  return (
    <TableContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: height,
        borderRadius: "10px",
        px: 1,
        pb: 1,
      }}
    >
      <Table sx={{ position: "relative" }}>
        <TableHead
          sx={{
            borderTop: "1px solid #EAECF0",
            borderBottom: "1px solid #EAECF0",
            position: "sticky",
            top: "-1px",
            left: 0,
            background: "#fff",
            // zIndex: 1,
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                // borderRight: "1px solid #e2e8f0"
                p: "10px",
              }}
            >
              <Typography
                fontWeight={"600"}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Name
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                //  borderRight: "1px solid #e2e8f0"
                p: "10px",
              }}
            >
              <Typography
                fontWeight={"600"}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Starts At
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                //  borderRight: "1px solid #e2e8f0"
                p: "10px",
              }}
            >
              <Typography
                fontWeight={"600"}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Report Id
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                // borderRight: "1px solid #e2e8f0"
                p: "10px",
              }}
            >
              <Typography
                fontWeight={"600"}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Participants
              </Typography>
            </TableCell>

            <TableCell sx={{ p: "10px" }}>
              <Typography
                fontWeight={"600"}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Meeting Link
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflowY: "auto" }}>
          {data &&
            data?.map((meetData, index) => (
              <>
                <TableRow
                  key={index}
                  sx={{
                    // height: "40px",
                    border: "none",
                    width: "100%",
                    pt: 2,
                  }}
                >
                  {Array.from({ length: 5 }, (_, index) => index).map(
                    (item, index) => {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            border: "none",
                            background: "#F2F4F7",
                            borderRadius:
                              index === 0
                                ? "8px 0 0 8px"
                                : index === 5 - 1
                                ? "0 8px 8px 0"
                                : "0",
                            height: "40px",
                            py: "8px",
                          }}
                        >
                          {index === 0 && (
                            <Typography
                              sx={{
                                fontFamily: "Open Sans",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              {formatDate(meetData.date).formattedDate}
                              <span
                                style={{
                                  fontFamily: "Open Sans",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  marginLeft: "5px",
                                }}
                              >
                                {formatDate(meetData.date).year}
                              </span>
                            </Typography>
                          )}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>

                {meetData.data.map((meeti, meetidx) => {
                  return (
                    <TableRow
                      key={meetidx}
                      onMouseOver={() => {
                        setTextColor("#fff");
                        setRowId(index);
                        setChildRowId(meetidx);
                      }}
                      onMouseOut={() => {
                        setTextColor("#000");
                      }}
                      sx={{
                        "&:hover": {
                          background: "#1E5267",
                        },
                        transition: "background-color 0.1s ease",
                        overflow: "hidden",
                        height: "55px",
                      }}
                      onClick={() => {
                        if (dashboard) {
                          handleClick();
                        }
                      }}
                      // sx={{
                      //   backgroundColor:
                      //     meetidx % 2 === 0 ? "#FFFFFF" : "#fff3eb", // even row color
                      // }}
                    >
                      <TableCell
                        sx={{
                          // borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          px: "16px",
                          borderRadius: "8px 0 0 8px",
                        }}
                      >
                        <Box sx={{}}>
                          <DisplayText
                            content={meeti?.name}
                            style={{ cursor: "pointer" }}
                            fontFamily="Open Sans"
                            color={
                              rowId === index && childRowId === meetidx
                                ? textColor
                                : "#000"
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          // borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          py: 0,
                          px: "16px",
                        }}
                      >
                        <Box sx={{}}>
                          <DisplayText
                            // content={moment(
                            //   meeti?.timestamp_start
                            // ).format("lll")}
                            content={extractTime(meeti?.timestamp_start)}
                            style={{ cursor: "pointer" }}
                            fontFamily="Open Sans"
                            color={
                              rowId === index && childRowId === meetidx
                                ? textColor
                                : "#000"
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          // borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          py: 0,
                          px: "16px",
                        }}
                      >
                        <Box sx={{}}>
                          <DisplayText
                            content={meeti?.report_id}
                            style={{ cursor: "pointer" }}
                            fontFamily="Open Sans"
                            color={
                              rowId === index && childRowId === meetidx
                                ? textColor
                                : "#000"
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          // borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          py: 0,
                          px: "16px",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {meeti?.participants
                            ?.slice(0, 4)
                            .map((parti, partk) => (
                              <Box
                                key={partk}
                                sx={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "20px",
                                  border: "1px solid white",
                                  background: colorCodes[partk],
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginLeft: partk === 0 ? "0" : "-5px",
                                  fontFamily: "Open Sans",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  color: partk > 2 ? "#000" : "#fff",
                                }}
                              >
                                {partk > 2
                                  ? "+3"
                                  : capitalizeFirstTwoLetters(parti)}
                              </Box>
                            ))}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          py: 0,
                          px: "16px",
                          borderRadius: "0 8px 8px 0",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          {!!meeti?.vtb_link && (
                            <a
                              href={`${meeti?.vtb_link}`}
                              style={{
                                width: "80px",
                                backgroundColor:
                                  rowId === index && childRowId === meetidx
                                    ? textColor === "#fff"
                                      ? textColor
                                      : "#1E5267"
                                    : textColor === "#000"
                                    ? "#1E5267"
                                    : "#1E5267",
                                color:
                                  rowId === index && childRowId === meetidx
                                    ? textColor === "#fff"
                                      ? "#1E5267"
                                      : "#fff"
                                    : "#fff",
                                borderRadius: "5px",
                                textAlign: "center",
                                textDecoration: "none",
                                padding: "5px 0",
                              }}
                            >
                              Join
                            </a>
                          )}

                          {/* <Box
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenDelete({
                                id: meeti?.id,
                                state: true,
                              });
                            }}
                            sx={{
                              background:
                                rowId === index && childRowId === meetidx
                                  ? textColor === "#000"
                                    ? "#F2F4F7"
                                    : "transparent"
                                  : "#F2F4F7",
                              borderRadius: "8px",
                              height: "40px",
                              width: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteIcon
                              color={
                                rowId === index && childRowId === meetidx
                                  ? textColor === "#fff"
                                    ? textColor
                                    : "#000"
                                  : textColor === "#000"
                                  ? "#000"
                                  : "#000"
                              }
                            />
                          </Box> */}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

MeetingTable.propTypes = {
  data: PropTypes.any,
  height: PropTypes.any,
  dashboard: PropTypes.bool,
  handleClick: PropTypes.func,
};
export default MeetingTable;
