import mixpanel from "mixpanel-browser";

export const initializeXnaTracker = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
    debug: false,
    ignore_dnt: true,
  });

  mixpanel.people.set({ Name: localStorage.getItem("username") + "" });
};

export const trackXnaData = (tracking_data_name, additional_data = {}) => {
  mixpanel.track(tracking_data_name + "", additional_data);
};
