import HttpService from "./http.service";
import {
  loginURL,
  registerURL,
  inviteURL,
  verifyAccountURL,
  userURL,
  baseURL,
  xaiBaseURL,
} from "./url.service";

export const login = (data) => {
  return HttpService.post(loginURL, data);
};

export const signup = (data) => {
  return HttpService.post(registerURL, data);
};

export const requestInvite = (data) => {
  return HttpService.post(inviteURL, data);
};

export const verifyAccountRequest = (token) => {
  return HttpService.get(verifyAccountURL + "/" + token, {});
};

export const registerUser = (data) => {
  return HttpService.post(registerURL, data);
};

export const getCopilotRequest = (data) => {
  return HttpService.post(xaiBaseURL + "/copilot/chat", data);
};

export const getUser = (id) => {
  return HttpService.get(userURL + "?user_id=" + id, {});
};

export const getChatSessions = (page = 1) => {
  return HttpService.get(
    xaiBaseURL + "/copilot/chats?page=" + page + "&limit=10&ascending=false",
    {}
  );
};

export const getSessionMessages = (page = 1, sid) => {
  return HttpService.get(
    xaiBaseURL + "/copilot/chat/" + sid + "?page=" + page + "&limit=10",
    {}
  );
};

export const updateSessionName = (id, name) => {
  return HttpService.put(xaiBaseURL + "/copilot/chat/" + id, {
    session_name: name,
  });
};

export const getCurrentUserDetail = (uid = localStorage.getItem("uid")) => {
  return HttpService.get(baseURL + "get_user?user_id=" + uid, {});
};

export const updateUser = (data) => {
  return HttpService.post(baseURL + "update_user", data);
};

export const updateUserPassword = (data) => {
  return HttpService.post(baseURL + "update_user_password", data);
};
